import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "shell padding20px" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_payment_info = _resolveComponent("payment-info")
  const _component_payment_user_info = _resolveComponent("payment-user-info")
  const _component_payment_manage_info = _resolveComponent("payment-manage-info")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_payment_info, {
      orderDetail: _ctx.orderDetail,
      userType: "installer"
    }, null, 8, ["orderDetail"]),
    (_ctx.orderDetail.PayerType === '0')
      ? (_openBlock(), _createBlock(_component_payment_user_info, {
          key: 0,
          orderDetail: _ctx.orderDetail
        }, null, 8, ["orderDetail"]))
      : _createCommentVNode("", true),
    (_ctx.showPaymentManageInfo(_ctx.orderDetail.PayerType))
      ? (_openBlock(), _createBlock(_component_payment_manage_info, {
          key: 1,
          orderDetail: _ctx.orderDetail
        }, null, 8, ["orderDetail"]))
      : _createCommentVNode("", true)
  ]))
}