import { ListHeaderItem } from '@/components/common/list';
import { ChangeWordList } from '@/methods/setText';
import { isOffice } from '@/methods/role';

const showPaymentManageInfo = (PayerType: string) => {
    const orderDetailAction = ['1', '2', '3', '5'];
    return orderDetailAction.includes(PayerType);
};

const getInitData = () => {
    const headers: Array<ListHeaderItem> = [{
        name: 'OrderNumber',
        label: WordList.OrderOrderNumber
    }, {
        name: 'Type',
        label: WordList.TabelKeyInHtmlType
    }, {
        name: 'AptNumber',
        label: ChangeWordList.PaymentsNumInList
    }, {
        name: 'TotalPrice',
        label: WordList.OrderTotalPrice
    }, {
        name: 'Status',
        label: WordList.MulListUpdateListTanleStatus
    }, {
        name: 'CreateTime',
        label: WordList.TabelMessageBoxCreateTime
    }];
    const communityTypeOptions = [{
        value: 'all',
        label: WordList.SelectAll
    }, {
        value: '1',
        label: WordList.ProperAllTextActivate
    }, {
        value: '2',
        label: WordList.ProperAllTextSubscription
    }, {
        value: '3',
        label: WordList.AdditionalApp
    }];
    const officeTypeOptions = [{
        value: 'all',
        label: WordList.SelectAll
    }, {
        value: '1',
        label: WordList.ProperAllTextActivate
    }, {
        value: '2',
        label: WordList.ProperAllTextSubscription
    }, {
        value: '5',
        label: WordList.ProperAllTextFeature
    }];
    const typeOptions = isOffice() ? officeTypeOptions : communityTypeOptions;
    const statusOptions = [{
        value: 'all',
        label: WordList.SelectAll
    }, {
        value: '0',
        label: WordList.ProperAllTextProcessing
    }, {
        value: '1',
        label: WordList.ProperAllTextSuccessed
    }, {
        value: '2',
        label: WordList.PropertyAllTextFailed
    }, {
        value: '3',
        label: WordList.ProperAllTextTimeOut
    }, {
        value: '5',
        label: WordList.TabelFootCancel
    }, {
        value: '6',
        label: WordList.ProperAllTextSystemProcessing
    }];
    return {
        headers,
        typeOptions,
        statusOptions
    };
};

export default null;
export {
    showPaymentManageInfo,
    getInitData
};