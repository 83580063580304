
import {
    defineComponent, Ref, ref
} from 'vue';
import paymentInfo,
{ userInfo as paymentUserInfo, manageInfo as paymentManageInfo } from '@/components/view/common/payment/index';
import { order, orderType } from '@/data';
import { showPaymentManageInfo } from './util';

export default defineComponent({
    props: {
        id: {
            type: String,
            required: true
        }
    },
    components: {
        paymentInfo,
        paymentUserInfo,
        paymentManageInfo
    },
    setup(props) {
        const orderDetail: Ref<orderType.OrderInfo | {}> = ref({});
        order.getInfoInstaller(props.id, (data: orderType.OrderInfo) => {
            orderDetail.value = data;
        });

        return {
            orderDetail,
            showPaymentManageInfo
        };
    }
});
